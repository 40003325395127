body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Add this to your CSS file */
@media (max-width: 992px) {
  .navbar-collapse {
    position: fixed;
    top: 15%;
    left: -100%;
    width: 45%;
    height: 100%;
    background: white;
    transition: left 0.3s ease;
    z-index: 1050;
  }
  .navbar-collapse.show {
    left: 0;
    opacity: 0.95;
  }
  .navbar-collapse.show li {
      margin-left: 5%;
  }
  .navbar-toggler.collapsed + .navbar-collapse {
    left: -100%;
  }
}
